.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 覆蓋次選項的樣式 */
.ant-menu-submenu-popup .ant-menu-item {
  background-color: #272727 !important; /* 次選項的背景顏色 */
  color: #272727 !important; /* 次選項的文字顏色 */
}

/* 當次選項被選中時的樣式 */
.ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: #272727 !important; /* 選中時的背景顏色 */
  color: #ffffff !important; /* 你可以選擇讓選中的文字顏色和背景不同，例如白色 */
}


.custom-text {
  font-size: 32px;
}

.custom-text2 {
  font-size: 14.08px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-item {
  margin: 0 5px;
}

.page-item.disabled a {
  color: #ccc;
  pointer-events: none;
}

.page-item.active a {
  background-color: #1890ff;
  color: white;
}

.page-link {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  border: 1px solid #ddd;
  border-radius: 4px;
}


/*  */

.date-range-picker {
  width: 500px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 18px;
  margin: 0;
}

.tabs {
  display: flex;
  gap: 10px;
}

.tab {
  padding: 8px 16px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.tab.selected {
  background-color: #e0e0e0;
}

.quick-selection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.quick-btn {
  padding: 10px 16px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.quick-btn.selected {
  background-color: #d3e7ff;
  border-color: #69aaff;
}

.date-display {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-btn {
  background-color: transparent;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 8px;
}

.action-btns {
  display: flex;
  gap: 10px;
}

.cancel-btn, .apply-btn {
  padding: 10px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f3f3f3;
  color: #333;
}

.apply-btn {
  background-color: #007bff;
  color: #fff;
}

/* react-date-range default styles override */
.rdrCalendarWrapper {
  border-radius: 4px;
  border: 1px solid #ddd;
}



.ant-tabs-tab2 {
  border-bottom: none !important;
}


.custom-alert .ant-alert-icon {
  font-size: 16px; /* 調整圖標大小 */
}



/*layout的設定*/

/* Sider的下拉式選擇箭頭 */
.ant-menu-inline .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: 	#FFFFFF!important; /* 強制顏色 */
}

/* 滑鼠懸停效果 */
.ant-menu-item:hover, .ant-menu-submenu-title:hover {
  background-color: #505050 !important; /* 懸停背景顏色 */
}

/* 使用更高優先級設置選中項目的背景顏色 */
.ant-menu-item.ant-menu-item-selected, 
.ant-menu-submenu-title.ant-menu-submenu-title-selected {
  background-color: #505050 !important; /* 設置選中背景顏色 */
  color: #FFFFFF !important; /* 設置選中文字顏色 */
}

/* 子選項選中時的背景顏色 */
.ant-menu-item-selected {
  background-color: #404040 !important; /* 設置子選項的選中背景顏色 */
}

/* 確保懸停效果不影響選中樣式 */
.ant-menu-item:hover, .ant-menu-submenu-title:hover {
  background-color: #404040 !important; /* 懸停時的背景顏色 */
}



/* //儀錶板// */

/* 儀錶板中間四小塊區塊的設定 */
.four-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 16px;
}


/* 儀錶板中間問題區塊的設定 */
.question-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 16px;
}

/* 儀錶板中間應用程式區塊的設定 */
.app-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 16px;

}


/* 儀錶板最下面三區塊的設定 */
.scan-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 16px;
}

.scan-card {
  height: 500px;
  padding: 16px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-sizing: border-box;
}

.scan-header, .technology-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.scan-header {
  padding-bottom: 8px;
  border-bottom: 1px solid #E0E0E0; /* 底部分隔線 */
}

.scan-status {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #E0E0E0; /* 底部分隔線 */
}

.status-completed, .status-failed {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex: 1;
}

.status-completed {
  border-right: 1px solid #E0E0E0; /* 右側分隔線 */
}

.status-completed p, .status-failed p {
  font-size: 24px;
  margin: 4px 0;
}

.status-completed span, .status-failed span {
  color: #757575;
}

.scan-technology {
  margin-top: 16px;
  text-align: center;
}

.technology-header {
  margin-top: 16px;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  color: #9E9E9E;
}


/* 儀錶板最下面 Technology 區塊的設定 */
.scan-Technology {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  padding-bottom: 8px;
}


/* 儀錶板 中間應用程式區塊 風險評級橫條的設定 */
.progress-bar {
  width: 100%;
  min-width: 150px;
  height: 12px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .progress-bar {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .progress-bar {
    width: 60%;
  }
}


.Progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}



/* 儀錶板 問題區塊的橫線顏色設定 */



















